@font-face {
  font-family: GT-Walsheim;
  font-weight: 400;
  font-style: normal;
  src:
    local("GT-Walsheim-Regular"),
    url(../fonts/GT-Walsheim/GT-Walsheim-Regular.woff2) format("woff2"),
    url(../fonts/GT-Walsheim/GT-Walsheim-Regular.woff) format("woff"),
    url(../fonts/GT-Walsheim/GT-Walsheim-Regular.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: GT-Walsheim;
  font-weight: 700;
  font-style: normal;
  src:
    local("GT-Walsheim-Bold"),
    url(../fonts/GT-Walsheim/GT-Walsheim-Bold.woff2) format("woff2"),
    url(../fonts/GT-Walsheim/GT-Walsheim-Bold.woff) format("woff"),
    url(../fonts/GT-Walsheim/GT-Walsheim-Bold.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: GT-Walsheim;
  font-weight: 400;
  font-style: italic;
  src:
    local("GT-Walsheim-Regular-Oblique"),
    url(../fonts/GT-Walsheim/GT-Walsheim-Regular-Oblique.woff2) format("woff2"),
    url(../fonts/GT-Walsheim/GT-Walsheim-Regular-Oblique.woff) format("woff"),
    url(../fonts/GT-Walsheim/GT-Walsheim-Regular-Oblique.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: GT-Walsheim;
  font-weight: 700;
  font-style: italic;
  src:
    local("GT-Walsheim-Bold-Oblique"),
    url(../fonts/GT-Walsheim/GT-Walsheim-Bold-Oblique.woff2) format("woff2"),
    url(../fonts/GT-Walsheim/GT-Walsheim-Bold-Oblique.woff) format("woff"),
    url(../fonts/GT-Walsheim/GT-Walsheim-Bold-Oblique.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: SuisseWorks;
  font-weight: 400;
  font-style: normal;
  src:
    local("SuisseWorks-Regular"),
    url(../fonts/SuisseWorks/SuisseWorks-Regular-WebS.woff2) format("woff2"),
    url(../fonts/SuisseWorks/SuisseWorks-Regular-WebS.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: SuisseWorks;
  font-weight: 400;
  font-style: italic;
  src:
    local("SuisseWorks-RegularItalic"),
    url(../fonts/SuisseWorks/SuisseWorks-RegularItalic-WebS.woff2)
      format("woff2"),
    url(../fonts/SuisseWorks/SuisseWorks-RegularItalic-WebS.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: SuisseWorks;
  font-weight: 500;
  font-style: normal;
  src:
    local("SuisseWorks-Medium"),
    url(../fonts/SuisseWorks/SuisseWorks-Medium-WebS.woff2) format("woff2"),
    url(../fonts/SuisseWorks/SuisseWorks-Medium-WebS.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: SuisseWorks;
  font-weight: 500;
  font-style: italic;
  src:
    local("SuisseWorks-MediumItalic"),
    url(../fonts/SuisseWorks/SuisseWorks-MediumItalic-WebS.woff2)
      format("woff2"),
    url(../fonts/SuisseWorks/SuisseWorks-MediumItalic-WebS.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: SuisseWorks;
  font-weight: 700;
  font-style: normal;
  src:
    local("SuisseWorks-Bold"),
    url(../fonts/SuisseWorks/SuisseWorks-Bold-WebS.woff2) format("woff2"),
    url(../fonts/SuisseWorks/SuisseWorks-Bold-WebS.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: SuisseWorks;
  font-weight: 700;
  font-style: italic;
  src:
    local("SuisseWorks-BoldItalic"),
    url(../fonts/SuisseWorks/SuisseWorks-BoldItalic-WebS.woff2) format("woff2"),
    url(../fonts/SuisseWorks/SuisseWorks-BoldItalic-WebS.woff) format("woff");
  font-display: swap;
}

html {
  font-size: clamp(0.75rem, 1vw, 1rem);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: GT-Walsheim, Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  line-height: 1.4;

  font-size: 1.25rem;
  color: var(--text-fg);
}

a {
  color: var(--link-fg);
  &:hover {
    color: var(--text-fg);

    .magazine & {
      color: var(--magazine-fg);
    }
  }
}

a[class]:not(.button, .some-link) {
  text-decoration: none;
  color: inherit;
}

.anchor {
  text-decoration: underline;
}

a:not([class]),
a.button--clean span {
  position: relative;
  color: var(--link-fg);
  z-index: 0;
  text-decoration: none;
  display: inline-block; /* The strange hover effect doesn't show if anchor spans more than one line */

  &:hover {
    color: var(--text-fg);

    .magazine & {
      color: var(--magazine-fg);
    }
  }

  &::after {
    z-index: -1;
    position: absolute;
    content: "";
    width: 100%;
    height: 0.33em;
    bottom: 0;
    left: 0;
    background-color: var(--link-decoration);
    transition: height 0.1s ease-in;
  }

  &:hover::after,
  &:focus-visible::after {
    height: 1.2em;
  }
}

.prose {
  h1 {
    text-align: center;
    max-width: var(--w-prose);
    margin-inline: auto;
    margin-bottom: var(--spacing);
  }
  h2,
  h3 {
    text-align: left;

    /* make it centered for all home teaser titles */
    .home & {
      text-align: center;
    }
    max-width: var(--w-prose);
    margin-inline: auto;
    margin-top: 2em;
    margin-bottom: var(--spacing);
  }

  h4 {
    text-align: left;
    max-width: var(--w-prose);
    margin-inline: auto;
    margin-top: 1em;
    margin-bottom: var(--spacing);
  }

  p,
  .button,
  figure,
  ul,
  ol,
  table,
  .embedded-video {
    max-width: var(--w-prose);
    margin-inline: auto;
    margin-bottom: var(--spacing);
  }
  .caption {
    margin-bottom: var(--spacing);
  }
  .embedded-video.with-caption {
    margin-bottom: 0;

    & + .caption {
      max-width: var(--w-prose);
      margin-inline: auto;
    }
  }
}

main > .container.prose:first-child > {
  h1,
  h2,
  h3,
  h4 {
    margin-top: 0; /* so bad, but i dont change the whole layout function at this point.. */
  }
}

h1,
h2,
h3,
h4 {
  line-height: 1.1;
}

/* sepcial case for h2 in follow up proses */
.home .prose + .prose h2 {
  margin-top: var(--margin-m);
}

figcaption,
.caption {
  margin: 0.75rem 0 0;
  text-align: center;
}

figcaption,
.caption,
small {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.1;
}

.lead {
  font-size: 1.75rem;
  line-height: 2.25rem;
}

.centered {
  text-align: center;
}

h1,
h2 {
  font-size: 4rem;
  line-height: 1.125em;
  font-style: normal;
  font-weight: bold;
  text-align: center;

  @media (--media-sm-only) {
    font-size: 3rem;
  }
}

h3 {
  font-size: 3.5rem;
  line-height: 1.1em;
  font-style: normal;
  font-weight: bold;

  @media (--media-sm-only) {
    font-size: 2.5rem;
  }
}
h4,
h5,
h6 {
  font-size: 1.75rem;
  font-style: normal;
  font-weight: bold;
  line-height: 1.1em;

  @media (--media-sm-only) {
    font-size: 1.5rem;
  }
}

.hero-container {
  p {
    font-size: 2rem;
    line-height: 1.3;
    color: var(--hero-font-color);

    @media (--media-sm-only) {
      font-size: 1.5rem;
    }
  }
}
.hero-container--large {
  h3 {
    font-size: 4rem;
    line-height: 4.5rem;
    color: var(--hero-font-color);

    @media (--media-sm-only) {
      font-size: 3rem;
    }
  }
}

.hero-container--small {
  h3 {
    font-size: 3.5rem;
    line-height: 1.1;
    color: var(--hero-font-color);

    @media (--media-sm-only) {
      font-size: 2.5rem;
    }
  }
}

.magazine main blockquote > p:not(.lead) {
  font-family: SuisseWorks, serif;
  font-size: 2rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.5;

  &::before {
    content: "«";
    display: inline;
  }
  &::after {
    content: "»";
    display: inline;
  }
}

.magazine main .prose {
  h2:not([class]),
  h3:not([class]),
  h4:not([class]) {
    font-family: GT-Walsheim-Regular, sans-serif;
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: normal;
    color: var(--lime);
    margin-block: 3rem 1rem;
  }
}

.magazine main,
.magazine-teaser,
.magazine--detail main .magazine--similar-articles,
.container--articles {
  --link-fg: var(--gray);

  h1 {
    font-family: SuisseWorks, serif;
    font-size: 4.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.1em;
    text-align: center;
    color: var(--gray);

    @media (--media-sm-only) {
      font-size: 3rem;
    }
  }

  h2 {
    font-family: SuisseWorks, serif;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.1em; /* different than in figma */
    text-align: center;
    color: var(--gray);
    margin-bottom: var(--spacing);

    @media (--media-sm-only) {
      font-size: 3rem;
    }
  }

  h3,
  .article-box--featured h4 {
    font-family: SuisseWorks, serif;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.1em;
    color: var(--gray);

    @media (--media-sm-only) {
      font-size: 1.5rem;
    }
  }

  h4 {
    font-family: SuisseWorks, serif;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.1em;
    color: var(--gray);

    @media (--media-sm-only) {
      font-size: 2rem;
    }
  }

  h5,
  h6 {
    font-family: SuisseWorks, serif;
    font-size: 2rem;
    font-style: normal;
    line-height: 1.1em;
    color: var(--gray);

    @media (--media-sm-only) {
      font-size: 1.3rem;
    }
  }

  p,
  .some-link {
    font-size: 1.25rem;
  }
}

.magazine main {
  /* hero overrides */
  .hero h3 {
    color: var(--hero-font-color);
  }

  .hero-container--large h3 {
    font-size: 4rem;
    line-height: 4.5rem;

    @media (--media-sm-only) {
      font-size: 2.5rem;
    }
  }

  .article-box__author {
    font-size: 1.125rem;
    font-weight: 400;
  }

  .article-box__details {
    p:not(.lead) {
      font-family: GT-Walsheim, Helvetica, Arial, sans-serif;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4em;
    }
  }

  .lead {
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 1.4;
  }

  p:not(.lead, .newsletter-form__claim, .interview-question) {
    font-family: SuisseWorks, serif;
    line-height: 1.6;
  }
}

.magazine--detail main {
  --link-decoration: var(--pink);

  h1 {
    margin-top: 1rem;
    margin-bottom: 0;
  }
  h2,
  h3 {
    margin-top: calc(2 * var(--spacing));
    text-align: left;
  }

  h4 {
    font-size: 1.5rem;
    font-weight: 400;
    font-family: GT-Walsheim, Helvetica, Arial, sans-serif;
    color: var(--lime);
    margin-bottom: 1rem;

    @media (--media-sm-only) {
      font-size: 1.25rem;
    }
  }

  figure img {
    margin: 0 auto;
    max-width: min(100%, var(--w-content));
  }

  .caption,
  figcaption {
    text-align: left;
  }

  .container--artwork {
    padding: 1rem 0 3.5rem 0;
  }

  .container--rel-info {
    margin-top: calc(2 * var(--spacing));
    max-width: var(--c6);
    color: var(--dark);
    font-size: 1.125rem;

    h4 {
      font-family: unset;
      font-size: 2rem;
      color: var(--dark);
      margin-bottom: var(--gap);
    }
  }

  .card__director {
    font-family: GT-Walsheim, Helvetica, Arial, sans-serif;
    color: var(--white);
  }

  .director-box__portrait {
    max-width: 100px;
    max-height: 100px;
    border-radius: 50%;
    vertical-align: middle;
  }

  .director-box__name,
  .director-box p {
    font-family: inherit;
    font-size: 1.25rem;
    margin: 0;
    text-align: left;
    line-height: 1.4;
  }

  .director-box .button--link {
    margin-bottom: 0;
  }

  .director-grid {
    --grid-cell: 100%;
    margin-block: var(--spacing);
  }

  .director-box__name {
    font-weight: 700;
  }

  .card__film {
    margin-bottom: var(--margin);
  }
}

.magazine-teaser__description p:not(.lead) {
  font-family: GT-Walsheim, Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
  line-height: 1.42;
  width: var(--w-content);
  margin-inline: auto;
}

/* Sorry, ugly override needed */
.magazine--detail main .magazine--similar-articles h2 {
  text-align: left;
  margin-bottom: 3rem;
}

body.association {
  h1 {
    text-align: center;
    font-family: SuisseWorks, serif;
    font-size: 4.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.1em;
  }
  h2 {
    font-family: SuisseWorks, serif;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4rem;
  }
}

.interview-question {
  font-family: GT-Walsheim;
  font-weight: bold;
}
