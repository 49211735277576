.vimeo-player {
  position: relative;

  display: flex;
  width: 100%;
  justify-content: center;

  background: #000;

  &::after {
    position: absolute;
    inset: 0;
    content: "";
    background: url(/static/assets/play.svg) no-repeat center;
    opacity: 0;
    transform: scale(1.3);
    transition: all 0.3s ease-out;

    cursor: pointer;
  }
}

.vimeo-player:not(.playing) {
  &::after {
    opacity: 1;
    transform: scale(1);
  }

  .vimeo-player__controls .button {
    opacity: 0;
  }
}

.vimeo-player .vimeo-player__controls {
  position: absolute;
  bottom: 0.5rem;
  right: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  transition: opacity 0.3s ease-out;

  padding: 0.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .button {
    padding: 0.5rem;
    flex-shrink: 0;
    margin: 0;
    --icon-size: 3rem;
    --button-fg: var(--lime);
  }
}

.vimeo-player.played.controls-visible {
  &:hover,
  &:focus-visible {
    .vimeo-player__controls {
      opacity: 1;
    }
  }
}

.vimeo-player__controls.vimeo-player__controls--top {
  top: 0;
  bottom: unset;

  .vimeo-link {
    margin-left: auto;
    .icon {
      margin-top: 0.5rem;
      padding: 0.575rem;
    }
  }
}

.vimeo-player__controls {
  .progress-control {
    flex-grow: 1;
    max-width: 50%;
    height: 2rem;
    display: flex;
    align-items: center;
  }

  progress[value].progress {
    width: 100%;
    height: 0.5rem;
  }

  progress.progress {
    appearance: none;
    &::-webkit-progress-bar {
      background-color: var(--black-400);
      height: 0.5rem;
      border-radius: 0.5rem;
    }
    &::-webkit-progress-value {
      background-color: var(--lime);
      height: 0.5rem;
      border-radius: 0.5rem;
    }
  }

  .progress-time {
    color: var(--lime);
    font-size: 0.75rem;
    margin: 0 0.5rem;
  }
}

.disable-fullscreen .button[data-fullscreen] {
  visibility: hidden;
}
