.plugin {
  margin: var(--margin-m) auto;
  width: 100%;
}

.plugin--button {
  max-width: var(--w-prose);
  margin-inline: auto;
  text-align: left;
  padding-left: 0;
}

.plugin--wrap {
  padding: var(--margin-m) 0;
  background-color: var(--feature-bg);
  color: var(--feature-fg);

  h1,
  h2,
  h3 {
    &:first-child {
      margin-top: 0;
    }
  }

  & + .plugin--wrap {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.plugin--contained {
  max-width: var(--w-content);
  padding: 5rem 7rem;
  @media (--media-sm-only) {
    padding: 5rem 3rem;
    max-width: 100%;
  }

  .content-wrap {
    h1,
    h2,
    h3 {
      text-align: left;
      margin-bottom: 4.19rem;
    }
    .button {
      margin-top: 2rem;
      margin-bottom: 0;
      margin-right: auto;
    }
  }
}

.container + .plugin--wrap {
  margin-top: var(--margin-m);
}

.contact.card {
  max-width: var(--w-prose);
  margin-inline: auto;
  margin-block: var(--margin-m);
  background-color: var(--lime);
}
.contact__title {
  margin-bottom: 1.5rem;
}

.contact__link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  --icon-size: 1.5rem;
  --icon-fg: var(--dark);
}

.donation-projects {
  --grid-cell: var(--c4);
}

h2.donation-projects__title {
  margin-bottom: 4rem;
  color: var(--gray);
  text-align: left;
}

.donation-projects__content {
  margin-bottom: 4rem;
}

.projects-list {
  gap: var(--grid-gap--magazine);
  grid-template-columns: 1fr 1fr;
  margin-inline: auto;

  @media (--media-sm-only) {
    grid-template-columns: 1fr;
  }
}

.project {
  color: var(--dark);
}

h4.project__title {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.project__more .button {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem;
  padding: 0.75rem 1rem;
  margin-bottom: 0px;
  margin-top: 2rem;
}

.project__content p {
  margin-bottom: 1.5rem;
}

.project__progress {
  background-color: var(--dark);
  color: var(--gray);
  padding: 1.5rem;
}

.project__progress__header {
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1em;
  margin-bottom: 1.5rem;

  &.donation--done {
    display: flex;
    align-items: center;
    gap: 1rem;
    --icon-fg: var(--lime);
    --icon-size: 2.25rem;

    .icon {
      display: flex;
    }
  }
}

.project__progress__bar {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;

  progress {
    flex-grow: 2;
  }
  span {
    flex-grow: 1;
    flex-basis: 2rem;
  }
}

.donation-snippet {
  h2 {
    text-align: left;
    margin-bottom: 4.19rem;
  }
}

.donation-widget {
  margin-top: 4.19rem;
  margin-inline: 0;
  padding: 2rem;
  background: #fff;
}

.container--map {
  margin-bottom: var(--margin-m);
}

/* moz */
progress[value].progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--black-400);
  border-radius: 1rem;
  border: none;
  height: 1rem;

  &::-moz-progress-bar {
    background-color: var(--lime);
    border-radius: 1rem;
  }
}

/* webkit */
progress.progress {
  appearance: none;
  &::-webkit-progress-bar {
    background-color: var(--black-400);
    height: 1rem;
    border-radius: 1rem;
  }
  &::-webkit-progress-value {
    background-color: var(--lime);
    height: 1rem;
    border-radius: 1rem;
  }
}

.container--articles .print-teaser {
  background-color: var(--lime);
  color: var(--black);
  --button-color: var(--white);
  --button-bg: var(--black);

  padding: var(--spacing);

  h2,
  p {
    color: inherit;
  }

  h2 {
    text-align: left;
  }

  .button {
    margin-right: auto;
    margin-top: var(--margin);

    @media (hover: hover) {
      &:hover {
        color: var(--button-bg);
        background: var(--white);
        border-color: var(--white);
        --icon-fg: var(--button-bg);
      }
    }
  }
}
